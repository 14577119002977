// PaymentForm.js
import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Make sure you've installed react-router-dom
import './KujiPaymentForm.css';
import NavBar from './NavBar';
import LoadingSpinner from './LoadingSpinner'; // Ensure you have this component created

function KujiPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Hook for programmatic navigation

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const selectedProduct = useSelector((state) => state.product.selectedProduct);
  const ticketsBought = useSelector((state) => state.payment.ticketsBought);
  const totalCost = selectedProduct ? parseFloat(selectedProduct.price.replace('$', '')) * ticketsBought : 0;
  const orderId = useSelector((state) => state.payment.orderId);

  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormLoading, setIsFormLoading] = useState(true); // New loading state
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentRequestAvailable, setPaymentRequestAvailable] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/order/get-total/${orderId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });
        const orderData = await response.json();
        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }
        return orderData.totalCost; // Assuming the API returns the totalCost directly
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };
    
    const initializePaymentRequest = async () => {
      if (stripe) {
        const totalCostCents = await fetchOrderDetails();

        sessionStorage.setItem('orderState', JSON.stringify({
          orderId: orderId,
          ticketsBought: ticketsBought,
          selectedProduct: selectedProduct
        }));

        const pr = stripe.paymentRequest({
          country: 'SG',
          currency: 'sgd',
          total: {
            label: 'Total',
            amount: totalCostCents, // Ensure totalCost is in cents
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });
    
        pr.canMakePayment().then(result => {
          if (result && result.applePay) {
            setPaymentRequest(pr);
            setPaymentRequestAvailable(true);
          }
        }).catch(error => {
          console.error("Payment request error: ", error);
        });
    
        pr.on('token', async (e) => {
          // Handle the submission of payment details
          const { token, error } = e;
    
          // Process the payment using the token.id...
          // Similar to handling confirmation in your existing handleSubmit
    
          if (error) {
            console.log(`Payment failed: ${error.message}`);
            e.complete('fail');
          } else {
            console.log('Payment succeeded!');
            // Navigate or update state as needed
            const response = await fetch(`${backendUrl}/api/draw-and-update-order/${orderId}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include'
            });
        
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            e.complete('success');

            navigate(`/draw`); // Adjust as per your route
          }
        });
      }
    }

    initializePaymentRequest();

  }, [stripe, navigate, backendUrl, orderId, ticketsBought, selectedProduct]);
  

  useEffect(() => {
    if (stripe) {
      // Assume if stripe is loaded, elements might be ready soon after
      // This is a simplification and might not accurately reflect the loaded state of the PaymentElement
      const timer = setTimeout(() => setIsFormLoading(false), 1000); // Wait for 1 second before assuming elements are loaded
      return () => clearTimeout(timer);
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.setItem('orderState', JSON.stringify({
      orderId: orderId,
      ticketsBought: ticketsBought,
      selectedProduct: selectedProduct
    }));
    setIsLoading(true); // Start loading

    try {
      const response = await fetch(`${backendUrl}/api/kuji/check-tickets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Ensure yourToken is the JWT token received after login
        },
        body: JSON.stringify({
          setId: selectedProduct.setId,
          ticketsBought: ticketsBought
        })
      });
      const data = await response.json();

      if (!response.ok) {
          throw new Error(data.message || 'Error checking ticket availability');
      }

      if (!data.success) {
          setIsLoading(false);
          setErrorMessage(data.message);
          setShowErrorPopup(true);
          // Show a popup or a message to the user indicating not enough tickets
          return;
      }
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setIsLoading(false); // Stop loading if Stripe.js hasn't loaded
        return;
      }
    
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // You still might need to specify a return_url for fallback, but it's used less often.
          return_url: `${window.location.origin}/draw`,
        },
        redirect: 'if_required', // This instructs Stripe to only redirect if necessary.
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        setIsLoading(false);
        console.log(result.error.message);
      } else {

        // The payment has been processed!
        if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {

          const response = await fetch(`${backendUrl}/api/draw-and-update-order/${orderId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include'
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          setIsLoading(false);

          navigate(`/draw`); // Adjust as per your route
        }
      } 
    } catch (error) {
      setIsLoading(false);
      console.log('Error: ', error);
    }
  };

  const goToProducts = () => {
    const targetUrl = '/ichiban-kuji-products';
    window.location.href = `${window.location.origin}${targetUrl}`;
  }

  return (
    <div>
        <NavBar />
        <div className="gradient-background">
            {showErrorPopup && (
              <div className="tickets-error-popup-overlay">
                <div className="tickets-error-popup-content">
                  <p>{errorMessage}</p>
                  <button onClick={goToProducts}>Go</button>
                </div>
              </div>
            )}
            <div className="kuji-payment-container">
                <div className="kuji-payment-info">
                    <h2 className="kuji-payment-details">Payment Details</h2>
                    {selectedProduct && (
                        <>
                            <img className="kuji-payment-product-image" src={`${backendUrl}/api/images/${selectedProduct.image}`} alt={selectedProduct.name} />
                            <p className='kuji-payment-product-name p-text'>{selectedProduct.name}</p>
                            <p className='kuji-payment-tickets-bought p-text'>Tickets: {ticketsBought}</p>
                            <p className='kuji-payment-cost p-text'>Total Cost: ${totalCost.toFixed(2)}</p>
                        </>
                    )}
                </div>
                <div className="kuji-payment-form">
                  {/* {paymentRequestAvailable && (
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                  )} */}
                  <form className="kuji-stripe-payment-element-form" onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button type="submit" className="kuji-pay-button" disabled={!stripe || isLoading}>
                      {isLoading ? 'Processing...' : 'Pay'}
                    </button>
                  </form>
                </div>
            </div>
        </div>
    </div>
  );
}

export default KujiPaymentForm;