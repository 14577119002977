  import React, { useEffect, useState } from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import { Navigate } from 'react-router-dom';
  import { setAuthState } from './redux/slices/authSlice';
  import { jwtDecode } from 'jwt-decode';

  function ProtectedRoute({ children }) {
    const { isAuthenticated, isChecking } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const checkAuthentication = () => {
        const token = localStorage.getItem('token');
        if (token) {
          try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
              console.log("Token expired.");
              // Token has expired
              dispatch(setAuthState({ isAuthenticated: false }));
            } else {
              dispatch(setAuthState({ isAuthenticated: true }));
            }
          } catch (error) {
            console.error("Token invalid:", error);
            dispatch(setAuthState({ isAuthenticated: false }));
          }
        } else {
          console.log("No token found.");
          dispatch(setAuthState({ isAuthenticated: false }));
        }
        setIsLoading(false);
      };

      checkAuthentication();
    }, [dispatch]);

    if (isLoading || isChecking) {
      return <div>Authenticating...</div>; // Or some other loading indicator
    }

    return isAuthenticated ? children : <Navigate to="/login" state={{ from: 'auth' }} />;
  }

  export default ProtectedRoute;