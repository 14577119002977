import React, { useState, useRef } from 'react';
import bgmAudio from './bgm.mp3';

function TestAudio() {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio(bgmAudio));

    const togglePlay = () => {
        const promise = isPlaying ? audioRef.current.pause() : audioRef.current.play();
        if (promise !== undefined) {
            promise.then(() => {
                console.log('Playback succeeded');
            }).catch(error => {
                console.error('Error in playback:', error);
            });
        }
        setIsPlaying(!isPlaying);
    };    

    const setVolumeZero = () => {
        audioRef.current.volume = 0;
        console.log(`Volume set to: ${audioRef.current.volume}`);
        // Reset source to enforce volume change
        audioRef.current.src = audioRef.current.src;
        audioRef.current.load();
    };
    

    return (
        <div>
            <audio src={bgmAudio} controls />
            <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button>
            <button onClick={setVolumeZero}>Set Volume to 0</button>
        </div>
    );
}

export default TestAudio;