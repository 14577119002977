import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthState } from './redux/slices/authSlice';
import HomePage from './HomePage';
import IchibanKujiProducts from './IchibanKujiProducts';
import BlindBoxesProducts from './BlindBoxesProducts';
import LoginPage from './LoginPage';
import VerificationPage from './VerificationPage';
import SignupPage from './SignupPage';
import VerifyEmailPage from './VerifyEmailPage';
import KujiPaymentPage from './KujiPaymentPage';
import DrawPage from './DrawPage';
import ResultsPage from './ResultsPage';
import ProfilePage from './ProfilePage';
import ResetPasswordPage from './ResetPasswordPage';
import VerifyResetPasswordPage from './VerifyResetPasswordPage';
import OrdersPage from './OrdersPage';
import OrderDetailsPage from './OrderDetailsPage';
import ShippingPaymentPage from './ShippingPaymentPage';
import ProtectedRoute from './ProtectedRoute';
import TermsAndConditions from './TermsAndConditions'; // Import the new component
import ShippingAndDelivery from './ShippingAndDelivery';
import ContactUs from './ContactUs';
import HowItWorks from './HowItWorks';
import TestAudio from './TestAudio';
import FAQ from './FAQ';
import AboutUs from './AboutUs';
import TradingMarketplace from './TradingMarketplace';
import CreateTrade from './CreateTrade';
import PurchaseTokensPage from './PurchaseTokensPage';
import TokenPaymentPage from './TokenPaymentPage';
import UserTokensPage from './UserTokensPage';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/verify" element={<VerificationPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/ichiban-kuji-products" element={<IchibanKujiProducts />} />
        <Route path="/blind-boxes-products" element={<BlindBoxesProducts />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/user-profile" element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        } />
        <Route path="/orders" element={
          <ProtectedRoute>
            <OrdersPage />
          </ProtectedRoute>
        } />
        <Route path="/order-details/:orderId" element={
          <ProtectedRoute>
            <OrderDetailsPage />
          </ProtectedRoute>
        } />

        <Route path="/kuji-payment" element={
          <ProtectedRoute>
            <KujiPaymentPage />
          </ProtectedRoute>
        } />

        {/* <Route path="/trading-marketplace" element={
          <ProtectedRoute>
            <TradingMarketplace />
          </ProtectedRoute>
        } />

        <Route path="/create-trade" element={
          <ProtectedRoute>
            <CreateTrade />
          </ProtectedRoute>
        } /> */}

        <Route path="/purchase-tokens" element={
          <ProtectedRoute>
            <PurchaseTokensPage />
          </ProtectedRoute>
        } />

        <Route path="/token-payment" element={
          <ProtectedRoute>
            <TokenPaymentPage />
          </ProtectedRoute>
        } />

        <Route path="/tokens" element={
          <ProtectedRoute>
            <UserTokensPage />
          </ProtectedRoute>
        } />

        <Route path="/reset-password-verify" element={<VerifyResetPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/shipping-and-delivery" element={<ShippingAndDelivery />} />
        <Route path="/shipping-payment" element={<ShippingPaymentPage />} /> {/* Dynamic route for order details */}
        <Route path="/kuji-payment" element={<KujiPaymentPage />} />
        <Route path="/draw" element={<DrawPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/test-audio" element={<TestAudio />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;