import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import './OrdersPage.css';
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary
import ShipmentPopup from './ShipmentPopup';
import ShipmentSuccessPopup from './ShipmentSuccessPopup';
import tokenIcon from './surprize_token.png';

function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [showShipmentPopup, setShowShipmentPopup] = useState(false);
  const [userShippingAddress, setUserShippingAddress] = useState({});
  const [showShipmentSuccess, setShowShipmentSuccess] = useState(false);
  const [itemLimitExceeded, setItemLimitExceeded] = useState(false);
  const [totalItems, setTotalItems] = useState(0); // Track the number of items in the batch

  const location = useLocation();
  const navigate = useNavigate();
  const username = useSelector(state => state.user.username);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    if (location.state?.shipmentSuccess) {
      navigate(location.pathname, { state: {} });
      setShowShipmentSuccess(true);
    }
  }, [location, navigate]);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await fetch(`${backendUrl}/api/order/user/${username}`, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setOrders(data.orders);
        } else {
          // Handle errors
          console.error('Failed to fetch orders');
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchOrders();
    }
  }, []);

  const fetchUserShippingAddress = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/users/shipping-address/${username}`, {
        method: 'GET',
        credentials: 'include', // for session-based authentication
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
        },
      });
      if (response.ok) {
        const address = await response.json();
        setUserShippingAddress(address);
      } else {
        console.error('Failed to fetch user shipping address');
      }
    } catch (error) {
      console.error('Error fetching user shipping address:', error);
    }
  };

  const handleCardClick = (order) => {
    // Navigate to the detailed order page and pass the order details as state
    navigate(`/order-details/${order.orderId}`, { state: { order } });
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleCancelBatchShipping = () => {
    setShowCheckboxes(false);
  };

  const handleConfirmBatchShipping = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/shipment/check-item-limit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderIds: selectedOrders }),
      });

      const data = await response.json();

      if (response.status === 400) {
        // If item limit exceeded, show popup
        setItemLimitExceeded(true);
        setTotalItems(data.totalItems);
      } else {
        fetchUserShippingAddress();
        // Proceed with batch shipping if item limit is within bounds
        setShowShipmentPopup(true);
      }
    } catch (error) {
      console.error('Error checking item limit:', error);
    }
  };

  const handleCloseShipmentSuccessPopup = () => {
    setShowShipmentSuccess(false);
  }

  console.log(selectedOrders);

  return (
    <div>
      <NavBar />
      <div className='gradient-background'>
        {showShipmentSuccess && <ShipmentSuccessPopup onClose={handleCloseShipmentSuccessPopup} />}
        {itemLimitExceeded && (
          <div className="item-limit-popup">
            <div className="item-limit-popup-content">
              <p>Item limit exceeded! You are trying to ship {totalItems} items, but the limit is 10.</p>
              <button onClick={() => setItemLimitExceeded(false)}>Close</button>
            </div>
          </div>
        )}
        <div className='orders-container'>
          <div className='orders-top'>
            {showCheckboxes ? (
              <>
                <button className='batch-ship-confirm-button' onClick={handleConfirmBatchShipping}>Ship Orders</button>
                <button className='batch-cancel-button' onClick={handleCancelBatchShipping}>Cancel</button>
              </>
            ) : (
              <>
                <h2 className='orders-title'>Orders</h2>
                <button className='batch-ship-button' onClick={() => setShowCheckboxes(true)}>Batch Shipping</button>
              </>
            )}
          </div>
          {isLoading ? <LoadingSpinner /> : orders.length > 0 ? (
            orders.filter(order => !showCheckboxes || (!order.shipped && !order.preorder)).map((order) => (
              showCheckboxes ? (
                <div className="order-row" key={order.orderId}>
                  <input
                    type="checkbox"
                    checked={selectedOrders.includes(order.orderId)}
                    onChange={() => handleSelectOrder(order.orderId)}
                  />
                  <div className="order-card" onClick={() => handleCardClick(order)}>
                    <div className="order-top">
                      <div className="order-id">{order.orderId}</div>
                      {
                          order.shipped
                          ? <div className="order-shipped">Shipped</div>
                          : <div className="order-not-shipped">Click to Ship</div>
                      }
                    </div>
                    <div className="order-content">
                      <img src={`${backendUrl}/api/images/${order.productImage}`} alt="Product" />
                      <div className="order-details">
                          <div className="product-name">{order.productName}</div>
                          <div className="ticket-calculation">
                              {
                                order.paymentIntentId == "TOKEN"
                                ? <div className="ticket-cost">{order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/order.numberOfTickets) : 'N/A'} <img src={tokenIcon} alt="Token" className="order-token-icon" /></div>
                                : <div className="ticket-cost">${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100/order.numberOfTickets).toFixed(2) : 'N/A'}</div>
                              }
                              <div className="number-of-tickets">x{order.numberOfTickets}</div>
                          </div>
                      </div>
                    </div>
                    <div className="order-bottom">
                      {
                        order.lotteryEnabled
                        ? order.selectedPrizes.length > 0
                          ? <div className="selected-prizes">{order.selectedPrizes.sort().join(', ')}</div>
                          : <div className="selected-prizes">Please wait for CHANCE tickets to be sold out.</div>
                        : <div className="selected-prizes">{order.selectedPrizes.sort().join(', ')}</div>
                      }
                      {
                        order.paymentIntentId === "TOKEN"
                        ? (<div className="order-page-total-cost">
                            Total: <strong>{order.totalCost['$numberDecimal']}</strong><img src={tokenIcon} alt="Token" className="order-token-icon" />
                          </div>)
                        : (<div className="order-page-total-cost">
                            Total: <strong>${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100).toFixed(2) : 'N/A'}</strong>
                          </div>)
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div className="order-card" key={order.orderId} onClick={() => handleCardClick(order)}>
                  <div className="order-top">
                    <div className="order-id">{order.orderId}</div>
                    {
                        order.shipped
                        ? <div className="order-shipped">Shipped</div>
                        : <div className="order-not-shipped">
                          {order.preorder ? "PRE-ORDER" : "Click to Ship"}
                        </div>
                    }
                  </div>
                  <div className="order-content">
                    <img src={`${backendUrl}/api/images/${order.productImage}`} alt="Product" />
                    <div className="order-details">
                        <div className="product-name">{order.productName}</div>
                        <div className="ticket-calculation">
                          {
                            order.paymentIntentId == "TOKEN"
                            ? <div className="ticket-cost">{order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/order.numberOfTickets) : 'N/A'} <img src={tokenIcon} alt="Token" className="order-token-icon" /></div>
                            : <div className="ticket-cost">${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100/order.numberOfTickets).toFixed(2) : 'N/A'}</div>
                          }
                          <div className="number-of-tickets">x{order.numberOfTickets}</div>
                        </div>
                    </div>
                  </div>
                  <div className="order-bottom">
                    {
                      order.lotteryEnabled
                      ? order.selectedPrizes.length > 0
                        ? order.lotteryWon
                          ? <div className="selected-prizes">{order.selectedPrizes.sort().join(', ')} (CHANCE Won!)</div>
                          : <div className="selected-prizes">{order.selectedPrizes.sort().join(', ')} (CHANCE Lost!)</div>
                        : <div className="selected-prizes">Please wait for CHANCE tickets to be sold out.</div>
                      : <div className="selected-prizes">{order.selectedPrizes.sort().join(', ')}</div>
                    }                    
                    {
                      order.paymentIntentId === "TOKEN"
                      ? (<div className="order-page-total-cost">
                          Total: <strong>{order.totalCost['$numberDecimal']}</strong><img src={tokenIcon} alt="Token" className="order-token-icon" />
                        </div>)
                      : (<div className="order-page-total-cost">
                          Total: <strong>${order.totalCost['$numberDecimal'] ? parseFloat(order.totalCost['$numberDecimal']/100).toFixed(2) : 'N/A'}</strong>
                        </div>)
                    }
                  </div>
                </div>
              )
            ))
          ) : (
            <div className="no-orders-message">No orders yet.</div>
          )}
        </div>
      </div>
      {showShipmentPopup && <ShipmentPopup
            orderIds={selectedOrders} // Pass the single order in an array
            onCancel={() => setShowShipmentPopup(false)}
            userShippingAddress={userShippingAddress}
            setShowShipmentPopup={setShowShipmentPopup}
          />
      }
    </div>
  );
}

export default OrdersPage;