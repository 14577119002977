// ImagePopup.js
import React, { useEffect } from 'react';
import './ImagePopup.css'; // Make sure you have this CSS file for styling

function ImagePopup({ prize, src, onClose }) {

  return (
    <div className="image-popup">
      <div className="image-popup-content">
        <button className="image-popup-close" onClick={onClose}>✖</button>
        <div className="image-card">
          <img src={src} alt="Full Size" className="full-size-image" />
          <div className="image-name-description">Prize {prize.letter} ({prize.description})</div>
        </div>
      </div>
    </div>
  );
}

export default ImagePopup;
