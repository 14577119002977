import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from './redux/slices/userSlice';
import NavBar from './NavBar';
import logo from './logo.png'; // Import your logo
import LoadingSpinner from './LoadingSpinner';

function SignupPage() {
  const location = useLocation();
  const emailFromState = location.state?.email || ''; // Retrieve email from state
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [usernameDuplicateError, setUsernameDuplicateError] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-]).{6,}$/;
    return regex.test(password);
  };

  const validateUsername = (username) => {
    if (!/^[a-zA-Z0-9]{3,30}$/.test(username)) {
      setUsernameError('Username must be 3-30 characters long and alphanumeric.');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError('Password must be at least 6 characters and include a number, a letter, and a special character');
    } else {
      setPasswordError('');
    }
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    validateUsername(newUsername);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (newConfirmPassword !== password) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  // Modify handleSubmit to include validation logic
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setUsernameDuplicateError(''); // Reset the signup error message
    setError('');
    if (password !== confirmPassword) {
        setIsLoading(false);
        console.log('Passwords do not match');
        return;
    }
    if (!validatePassword(password)) {
      setIsLoading(false);
      console.log('Invalid password format');
      return;
    }

    // Prepare the data to be sent to the backend
    const userData = {
      username,
      email: emailFromState.toLowerCase(), // This email comes from the previous state
      password
    };

    // Send the request to the backend
    try {
      const response = await fetch(`${backendUrl}/api/users/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
        credentials: 'include'
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Account created successfully', data);
        localStorage.setItem('token', data.token); // Save the JWT in local storage
        dispatch(setUser({username: data.user.username, profilePicture: data.user.profilePicture}));
        sessionStorage.setItem('accountCreatedSuccess', 'true');
        setIsLoading(false);
        navigate('/'); // Redirect to homepage
        // Further actions such as redirecting to a login page or a success message
      } else {
        console.log('Failed to create account: ' + data.message);
        if (data.message === "Username already exists") {
            setIsLoading(false);
            setUsernameDuplicateError("Username already exists. Please choose a different username.");
        } else {
            // Handle all other types of errors
            setIsLoading(false);
            setError("An error occurred. Please contact support.");
        }
        // Handle account creation failure, e.g., showing an error message
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      setError("An error occurred. Please contact support.");
    }
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.container}>
            <form onSubmit={handleSubmit} style={styles.form}>
            <h2 style={styles.h2Text}>Sign Up</h2>
            <div style={styles.formContent}>
                <input
                type="text"
                id="username"
                placeholder="Username"
                value={username}
                onChange={handleUsernameChange}
                style={styles.input}
                />
                <input
                type="password"
                id="password"
                placeholder="Password"
                onChange={handlePasswordChange}
                value={password}
                style={styles.input}
                />
                <input
                type="password"
                id="confirm-password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                style={styles.input}
                />
                {usernameError && <div style={{ color: 'red' }}>{usernameError}</div>}
                {passwordError && <div style={{ color: 'red', width: '80%' }}>{passwordError}</div>}
                {confirmPasswordError && <div style={{ color: 'red', width: '80%' }}>{confirmPasswordError}</div>}
                {usernameDuplicateError && <div style={{ color: 'red', width: '80%' }}>{usernameDuplicateError}</div>}
                {error && <div style={{ color: 'red', width: '80%' }}>{error}</div>}
                {
                  isLoading
                  ? (
                    <LoadingSpinner />
                  )
                  : (
                    <button 
                    type="submit" 
                    style={styles.button}
                    disabled={!username || !password || !confirmPassword} // Disable button if any field is empty
                    >
                    Create Account
                    </button>
                  )
                }
            </div>
            </form>
        </div>
    </div>
    </div>
  );
}

const styles = {
  // Reuse or adjust the styles from your LoginPage
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px'
  },
  logo: {
    height: '100px', // Adjust as needed
  },
  input: {
    width: '70%',
    padding: '15px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '15px'
  },
  container: {
    width: '100%'
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    width: '60%', // Adjust the width as needed
    padding: '15px',
    marginTop: '40px',
    backgroundColor: '#4CAF50', // Base color for the button
    color: 'white',
    border: '1px solid green',
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: '0 6px #2e7d32', // Shadow effect for 3D look
    fontSize: '15px'
  }
};

export default SignupPage;