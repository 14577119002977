// TokenPaymentPage.js
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import TokenPaymentForm from './TokenPaymentForm'; // You'll create this component next

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function TokenPaymentPage() {
  const clientSecret = useSelector((state) => state.payment.clientSecret);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#32325d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '5px',
    },
    rules: {
      '.Label': {
        color: 'black',
      },
      '.Input--invalid': {
        color: '#df1b41',
      },
      '.Tab, .Block': {
        boxShadow: '0px 1px 1px rgba(50, 50, 93, 0.1)',
      },
    },
  };

  const options = {
    clientSecret: clientSecret,
    appearance: appearance
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <TokenPaymentForm />
    </Elements>
  );
}

export default TokenPaymentPage;