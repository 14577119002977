import React, { useState } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import emailjs from 'emailjs-com';
import './ContactUs.css'; // Make sure the path is correct

function ContactUs() {

    return (
        <div>
            <NavBar />
                <div className='contact-container'>
                    <h1 className='contact-title'>Contact Us</h1>
                    <p className='contact-email'>
                        For any enquiries, support, or anything else, please email us at
                        <a href="mailto:playsurprize@gmail.com"> playsurprize@gmail.com</a>.
                    </p>
                </div>
            <Footer />
        </div>
    );
}

export default ContactUs;