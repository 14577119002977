import React, { useState } from 'react';
import './PrizeCard.css'; // Make sure to create and include the corresponding CSS file
import ImagePopup from './ImagePopup';
import zhongLe from './zhongle.jpg';

function PrizeCard({ letter, imageSrc, description, quantity }) {
  const [isImageOpen, setIsImageOpen] = useState(false);

  const toggleImageOpen = () => setIsImageOpen(!isImageOpen);

  const showOverlay = quantity.startsWith('0');

  const prize = { letter: letter, description: description }

  return (
    <div className="prize-card">
        <div className="prize-title-box">
            <span>{quantity}</span>
        </div>
        <div className="prize-letter">{letter}</div>
        <div className="prize-banner-container" onClick={toggleImageOpen}>
          <img src={imageSrc} alt="Prize Banner" className="prize-banner" />
          {showOverlay && <img src={zhongLe} alt="Overlay" className="overlay-image" />}
        </div>        
        <p className="prize-description">{description}</p>
        {isImageOpen && (
          <ImagePopup prize={prize} src={imageSrc} alt="Prize Banner" onClose={toggleImageOpen} />
        )}
    </div>
  );
}

export default PrizeCard;
