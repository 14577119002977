import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedProduct, clearSelectedProduct } from './redux/slices/productSlice';
import { clearSelectedPrizes } from './redux/slices/prizesSlice';
import { resetPayment } from './redux/slices/paymentSlice';
import './ResultsPage.css';
import kujiTicket from './kujiticket.png'; // Make sure this path is correct
import ticketEffect from './ticketeffect.png'; // Import the ticket effect image
import NavBar from './NavBar';

function ResultsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPrizes = useSelector((state) => state.prizes.selectedPrizes);

  const onTryAgain = () => {
    navigate('/ichiban-kuji-products', { state: { retry: true } });
  };

  const onBrowse = () => {
    dispatch(clearSelectedProduct());
    navigate('/ichiban-kuji-products');
  };

  // Cleanup function to clear session storage and reset Redux state
  useEffect(() => {
    return () => {
      sessionStorage.clear(); // Clears the entire sessionStorage
      dispatch(clearSelectedPrizes()); // Resets selected prizes state in Redux
      dispatch(resetPayment());
    };
  }, [dispatch]);

  const sortedSelectedPrizes = [...selectedPrizes].sort();

    return (
      <div>
        <NavBar />
        <div className="gradient-background">
          <div className="results-popup">
            <h2 className="text">YOU WON</h2>
            <div className="tickets-container" style={{ backgroundImage: `url(${ticketEffect})` }}>
              {sortedSelectedPrizes.map((prize, index) => (
                <div className="ticket" key={index}>
                  <img src={kujiTicket} alt={`Kuji Ticket ${prize}`} />
                  <div className="prize-text">{prize}赏</div>
                </div>
              ))}
            </div>
            <button className="try-button" onClick={onTryAgain}>Try Again</button>
            <button className="browse-button" onClick={onBrowse}>Browse Others</button>
          </div>
        </div>
      </div>
    );
}

export default ResultsPage;