// src/redux/slices/prizesSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const prizesSlice = createSlice({
  name: 'prizes',
  initialState: {
    selectedPrizes: [],
  },
  reducers: {
    setSelectedPrizes: (state, action) => {
      state.selectedPrizes = action.payload;
    },
    clearSelectedPrizes: (state) => {
      state.selectedPrizes = [];
    },
  },
});

export const { setSelectedPrizes, clearSelectedPrizes } = prizesSlice.actions;

export default prizesSlice.reducer;
