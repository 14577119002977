// HoldTimePopup.js
import React from 'react';
import './HoldTimePopup.css';

const HoldTimePopup = ({ holdTimeLeft, onClose }) => (
  <div className="hold-popup">
    <div className="hold-popup-content">
      <h2>Buying out in progress</h2>
      <p>Please wait {holdTimeLeft} seconds and refresh your page to purchase with the updated odds.</p>
      <button className="hold-popup-close-button" onClick={onClose}>Close</button>
    </div>
  </div>
);

export default HoldTimePopup;