// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('userState');
    if (serializedState === null) {
      return undefined;
    }
    return { user: JSON.parse(serializedState) }; // Ensure this matches the structure expected by your rootReducer
  } catch (err) {
    return undefined;
  }
};

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(() => {
  try {
    const userState = store.getState().user;
    const serializedState = JSON.stringify(userState);
    localStorage.setItem('userState', serializedState);
  } catch (err) {
    console.error("Could not serialize state", err);
  }
});

export default store;