import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { logoutUser, setUser } from './redux/slices/userSlice'; // Adjust the path as necessary
import './ProfilePage.css';
import NavBar from './NavBar';
import defaultProfilePic from './default_profile_picture.jpg';
import { countries } from 'countries-list';
import LoadingSpinner from './LoadingSpinner'; // Adjust the path as necessary

function ProfilePage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const username = useSelector(state => state.user.username);
  const profilePicture = useSelector(state => state.user.profilePicture);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(defaultProfilePic);
  const [editMode, setEditMode] = useState(false);
  const [localUsername, setLocalUsername] = useState(username);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [country, setCountry] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [fullName, setFullName] = useState('');
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [profilePicChanged, setProfilePicChanged] = useState(false);
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
  const [postalCodeChanged, setPostalCodeChanged] = useState(false);
  const [unitNumberChanged, setUnitNumberChanged] = useState(false);
  const [countryChanged, setCountryChanged] = useState(false);
  const [fullAddressChanged, setFullAddressChanged] = useState(false);
  const [fullNameChanged, setFullNameChanged] = useState(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const [showProfileUpdatePrompt, setShowProfileUpdatePrompt] = useState(false); // New state to control the prompt
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  },[editMode])

  useEffect(() => {
    if (location.state?.editMode) {
      setEditMode(true);
    }
    // Rest of your useEffect for fetching user details...
  }, [location.state]);

  useEffect(() => {
    if (!username) navigate('/login');
  }, [username, navigate]);

  useEffect(() => {
    if (profilePicture) {
        setPreviewImage(`${backendUrl}/api/images/${profilePicture}`);
    }
  }, [profilePicture])

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!username) return;
      setIsLoading(true);
  
      try {
        const response = await fetch(`${backendUrl}/api/users/details/${username}`, {
          method: 'GET',
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          // Update state with fetched data
          setPhoneNumber(data.phoneNumber);
          setPostalCode(data.shippingAddress.postalCode);
          setUnitNumber(data.shippingAddress.unitNumber);
          setCountry(data.shippingAddress.country);
          setFullAddress(data.shippingAddress.fullAddress);
          setFullName(data.shippingAddress.fullName);
          
          const detailsAreIncomplete = !data.phoneNumber || !data.shippingAddress.postalCode || 
            !data.shippingAddress.unitNumber || !data.shippingAddress.country || 
            !data.shippingAddress.fullAddress || !data.shippingAddress.fullName;

          setShowProfileUpdatePrompt(detailsAreIncomplete);
          setIsLoading(false)
        } else {
          console.error('Failed to fetch user details');
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setIsLoading(false)
      }
    };
    fetchUserDetails();
  }, [username]); 

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleUsernameChange = (value) => {
    setLocalUsername(value);
    setUsernameChanged(value !== username); // Set true if changed
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
      setProfilePicChanged(true); // Image has been changed
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberChanged(value !== phoneNumber);
  };

  const handlePostalCodeChange = (value) => {
    setPostalCode(value);
    setPostalCodeChanged(value !== postalCode && validatePostalCodeSG(value));
    // The `validatePostalCodeSG` function ensures only valid changes are marked.
  };

  const handleUnitNumberChange = (value) => {
    setUnitNumber(value);
    setUnitNumberChanged(value !== unitNumber);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    setCountryChanged(value !== country);
  };

  const handleFullAddressChange = (value) => {
    setFullAddress(value);
    setFullAddressChanged(value !== fullAddress);
  };

  const handleFullNameChanged = (value) => {
    setFullName(value);
    setFullNameChanged(value !== fullName);
  };

  const handleSave = async () => {
    setIsSaving(true); // Start loading
    const formData = new FormData();
    if (usernameChanged) formData.append('username', localUsername);
    if (profilePicChanged) formData.append('profileImage', profileImage);
    if (phoneNumberChanged) formData.append('phoneNumber', phoneNumber);
    if (postalCodeChanged) formData.append('postalCode', postalCode);
    if (unitNumberChanged) formData.append('unitNumber', unitNumber);
    if (countryChanged) formData.append('country', country);
    if (fullAddressChanged) formData.append('fullAddress', fullAddress);
    if (fullNameChanged) formData.append('fullName', fullName);

    try {

        const response = await fetch(`${backendUrl}/api/users/update-profile`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}` // Assuming token is stored in localStorage
            },
            // Don't set 'Content-Type': 'multipart/form-data' header manually,
            // let the browser set it so the boundary is included
        });

        if (!response.ok) throw new Error('Failed to update profile');
        
        const result = await response.json();
        // Dispatch action to update user info in Redux store if needed
        // Navigate or show a success message
        if (result.token) {
          localStorage.setItem('token', result.token); // Update the token in localStorage
          console.log('Token updated.');
        }
        
        setEditMode(false); // Exit edit mode
        setShowUpdateSuccess(true); // Show success message
        setTimeout(() => setShowUpdateSuccess(false), 3000); // Hide after 3 seconds
        setUsernameChanged(false);
        setProfilePicChanged(false);
        dispatch(setUser({username: result.user.username, profilePicture: result.user.profilePicture}));
    } catch (error) {
        console.error('Error:', error);
        // Handle error, e.g., show error message
    } finally {
      setIsSaving(false); // Stop loading
    }
  };

  const handleCancel = () => {
    // Revert changes (if any) and exit edit mode
    setEditMode(false);
    setLocalUsername(username);
    setPreviewImage(profilePicture ? `${backendUrl}/api/images/${profilePicture}` : defaultProfilePic);
    setUsernameChanged(false);
    setProfilePicChanged(false);
    setPhoneNumberChanged(false);
    setPostalCodeChanged(false);
    setUnitNumberChanged(false);
    setCountryChanged(false);
    setFullAddressChanged(false);
    setFullNameChanged(false);
  };

  const handleLogout = async () => {
    // Make a request to the backend to clear the session
    try {
      const response = await fetch(`${backendUrl}/api/users/logout`, {
        method: 'GET',
        credentials: 'include', // Necessary to include cookies in the request
      });
      if (response.ok) {
        // Dispatch action to clear user state in Redux
        localStorage.removeItem('token'); // Remove the JWT stored in local storage
        dispatch(logoutUser());
        // Navigate to home or login page
        navigate('/');
      } else {
        console.error('Failed to log out');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleViewOrder = () => {
    navigate('/orders', { state: { username } });
  };

  const validatePhoneNumberSG = (phoneNumber) => {
    return /^(3|6|8|9)\d{7}$/.test(phoneNumber);
  };

  const validatePostalCodeSG = (postalCode) => {
    return /^\d{6}$/.test(postalCode);
  };

  const countryOptions = Object.keys(countries).map((countryCode) => ({
    code: countryCode,
    name: countries[countryCode].name,
  }));

  const isSaveEnabled = usernameChanged || profilePicChanged || 
  (phoneNumberChanged && validatePhoneNumberSG(phoneNumber)) || 
  (postalCodeChanged && validatePostalCodeSG(postalCode)) || 
  unitNumberChanged || countryChanged || fullAddressChanged || fullNameChanged;

  return (
    <div>
      <NavBar />
      <div className='gradient-background'>
            {showUpdateSuccess && (
              <div className="profile-update-success-message">
                Profile successfully updated!
              </div>
            )}
            {!editMode ? (
                isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <div className="profile-container">
                      <h2 className="profile-title">Welcome, {localUsername}</h2>
                      <div className="input-group-preview">
                          <img src={previewImage} alt="Profile" className="profile-image-preview" />
                      </div>
                      <div className="profile-info">
                        <p><strong>Phone Number:</strong> {phoneNumber}</p>
                        <div className="address">
                          <p><strong>Address:&nbsp;</strong></p>
                          <p>{postalCode} {unitNumber},&nbsp;</p>
                          <p>{country}</p>
                        </div>
                        {/* Conditional rendering for the update prompt */}
                        {showProfileUpdatePrompt && (
                          <p className="profile-update-prompt">Please update your profile shipping address</p>
                        )}
                      </div>
                      <button className="profile-button order-button" onClick={handleViewOrder}>View Orders</button>
                      <button className="profile-button edit-button" onClick={handleEdit}>Edit Profile</button>
                      <button className='profile-button logout-button' onClick={handleLogout}>Logout</button>
                    </div>
                  </>
                )
            ) : (
            <>
              <div className="edit-profile-container">
                <h2 className="edit-profile">Edit Profile</h2>
                <strong className="user-information">User Information</strong>
                <div className="image-group">
                    <label>Profile Image:</label>
                    <img src={previewImage} alt="Profile preview" className="edit-image-preview" />
                </div>
                <input className='file-select' type="file" onChange={handleImageChange} style={{ marginBottom: '15px' }}/>
                <div className="input-group">
                    <label>Username:</label>
                    <input type="text" value={localUsername} onChange={(e) => handleUsernameChange(e.target.value)} />
                </div>
                <div className="input-group">
                  <label htmlFor="phoneNumber">Phone Number:</label>
                  <input
                    id="phoneNumber"
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => handlePhoneNumberChange(e.target.value)}
                  />
                </div>
                <strong className="shipping-address">Shipping Address</strong>
                <div className="input-group">
                  <label htmlFor="fullName">Full Name:</label>
                  <input
                    id="fullName"
                    type="text"
                    value={fullName}
                    onChange={(e) => handleFullNameChanged(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Country:</label>
                  <select value={country} onChange={(e) => handleCountryChange(e.target.value)}>
                    {countryOptions.map(({ code, name }) => (
                      <option key={code} value={code}>{name}</option>
                    ))}
                  </select>
                </div>
                <div className="input-group">
                  <label htmlFor="postalCode">Postal Code:</label>
                  <input
                    id="postalCode"
                    type="text"
                    value={postalCode}
                    onChange={(e) => handlePostalCodeChange(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="fullAddress">Full Address:</label>
                  <input
                    id="fullAddress"
                    type="text"
                    value={fullAddress}
                    onChange={(e) => handleFullAddressChange(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="unitNumber">Unit Number:</label>
                  <input
                    id="unitNumber"
                    type="text"
                    value={unitNumber}
                    onChange={(e) => handleUnitNumberChange(e.target.value)}
                  />
                </div>
                {
                  isSaving ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <button className='save-profile-button button' onClick={handleSave} disabled={!isSaveEnabled}>Save Changes</button>
                      <button className='cancel-profile-button button' onClick={handleCancel}>Cancel</button>
                    </>
                  )
                }
              </div>
            </>
            )}
      </div>
    </div>
  );
}

export default ProfilePage;