import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  isChecking: true, // to indicate that the auth check is in progress
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isChecking = false;
    },
    resetAuthState: (state) => {
      state.isAuthenticated = false;
      state.isChecking = false;
    }
  }
});

export const { setAuthState, resetAuthState } = authSlice.actions;

export default authSlice.reducer;