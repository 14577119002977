// NavBar.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import navIcon from './navicon.png';
import tokenIcon from './surprize_token.png';

function NavBar() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const username = useSelector(state => state.user.username);
  const token = localStorage.getItem('token'); // Assuming you store the auth token in localStorage
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);

  useEffect(() => {
    if (username) {
      fetchTokenBalance();
    }
  }, [username]);

  const fetchTokenBalance = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/token/balance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTokenBalance(data.totalTokens || 0);
      } else {
        console.error('Failed to fetch token balance');
      }
    } catch (error) {
      console.error('Error fetching token balance:', error);
    }
  };

  const handleLogoClick = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const handleNavIconClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleTokenContainerClick = () => {
    navigate('/tokens');
  };

  return (
    <nav style={styles.navBar}>
      {
        username
        ? <div style={styles.leftContainer}>
            <div style={styles.navIconContainer} onClick={handleNavIconClick}>
              <img src={navIcon} alt="Nav Icon" style={styles.navIcon} />
              {menuOpen && (
                <div style={styles.dropdownMenu}>
                  <Link to="/user-profile" style={styles.dropdownLink}>View Profile</Link>
                  <Link to="/orders" style={styles.dropdownLink}>View Orders</Link>
                  <Link to="/purchase-tokens" style={styles.dropdownLink}>Purchase Tokens</Link>
                  {/* <Link to="/trading-marketplace" style={styles.dropdownLink}>Trading Marketplace</Link> */}
                </div>
              )}
            </div>
            <div style={styles.tokenContainer} onClick={handleTokenContainerClick}>
              <img src={tokenIcon} alt="Token" style={styles.tokenIcon} />
              <span style={styles.tokenCount}>{tokenBalance}</span>
            </div>
          </div>
        : <div style={styles.logoContainer} onClick={handleLogoClick}>
            <img src={logo} alt="Logo" style={styles.logo} />
          </div>
      }
      <div style={styles.authLinks}>
        {username ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={styles.logoContainer} onClick={handleLogoClick}>
              <img src={logo} alt="Logo" style={styles.logo} />
            </div>
          </div>
        ) : (
          <Link to="/login" style={styles.link}>Sign In / Sign Up</Link>
        )}
      </div>
    </nav>
  );
}

const styles = {
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#f8f8f8',
  },
  navIconContainer: {
    position: 'relative',
  },
  navIcon: {
    height: '30px',
    cursor: 'pointer',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '40px',
    left: '0',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    zIndex: 1000,
  },
  dropdownLink: {
    display: 'block',
    padding: '10px 20px',
    textDecoration: 'none',
    color: '#333',
  },
  logoContainer: {
    cursor: 'pointer',
  },
  logo: {
    height: '40px',
  },
  authLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  tokenContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    cursor: 'pointer', // Added cursor pointer
  },
  tokenIcon: {
    height: '32px',
    marginRight: '5px',
  },
  tokenCount: {
    fontSize: '18px',
    color: '#333',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
};

export default NavBar;