import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import './HowItWorks.css'; // Make sure you have this CSS file in your project

function HowItWorks() {
    return (
        <div>
            <NavBar />
                <div className='how-it-works-container'>
                    <h1 className='how-it-works-title'>How It Works</h1>
                    <ol className='how-it-works-list'>
                        <li>Purchase your tickets from our wide selection of Ichiban Kuji products.</li>
                        <li>Open your tickets to discover which prizes you've won!</li>
                        <li>Select the orders you want to ship and confirm your shipping details.</li>
                        <li>Receive your items within 2 to 4 working days!</li>
                    </ol>
                </div>
            <Footer />
        </div>
    );
}

export default HowItWorks;