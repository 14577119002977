import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard.js';
import './PopularProducts.css';
import LoadingSpinner from './LoadingSpinner.js';

function PopularProducts({ onPlayClick }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        // Replace with your actual backend endpoint
        const response = await fetch(`${backendUrl}/api/new-and-popular`);
        const data = await response.json();
        setProducts(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    };

    fetchProducts();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />; // Loading indicator for this section only
  }

  return (
    <div>
      <h2 className="new-popular-header">NEW AND POPULAR</h2>
      {products.map((product) => (
        <ProductCard key={product.setId} product={product} onPlayClick={onPlayClick} /> // Pass product and index as props
      ))}
    </div>
  );
}

export default PopularProducts;