import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentState } from './redux/slices/paymentSlice';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import LoadingSpinner from './LoadingSpinner';
import ticketIcon from './ticket.png';
import tokenIcon from './surprize_token.png';
import HoldTimePopup from './HoldTimePopup'; // Import the HoldTimePopup component
import LoginReminderPopup from './LoginReminderPopup'; // Import the popup component
import RefreshPopup from './RefreshPopup';
import TryAgainPopup from './TryAgainPopup';
import TokenConfirmationPopup from './TokenConfirmationPopup';
import TopUpTokensPopup from './TopUpTokensPopup'; // Import the new TopUpTokensPopup component
import './PlayProductCard.css';

function PlayProductCard({ product }) {
  const dispatch = useDispatch();
  let navigate = useNavigate(); // Hook for navigation

  const promo = product.promotion;
  const minimumTicketCount = promo && promo.type === 'CHALLENGE' ? promo.details.minimumQuantity : 1;
  
  const [ticketCount, setTicketCount] = useState(minimumTicketCount);
  const [inputValue, setInputValue] = useState(minimumTicketCount.toString()); // Use a separate state to track the input value
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showLoginReminder, setShowLoginReminder] = useState(false); // New state for showing the login reminder
  const [showRefreshPopup, setShowRefreshPopup] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [holdTimeLeft, setHoldTimeLeft] = useState(null); // State to track hold time left
  const [showTokenConfirmationPopup, setShowTokenConfirmationPopup] = useState(false); // New state for token confirmation popup
  const [showTopUpTokensPopup, setShowTopUpTokensPopup] = useState(false); // New state for top-up popup
  const [isBuyOutClick, setIsBuyOutClick] = useState(false);
  const [tokensLacking, setTokensLacking] = useState(0);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const quantityAvailable = parseInt(product.quantity.split('/')[0]);
  const isDisabled = quantityAvailable <= 0 || isLoading || holdTimeLeft !== null; // Disable button when loading or hold time left
  const username = useSelector((state) => state.user.username);

  const isPromoActive = promo && promo.type === 'BUY_X_GET_Y_FREE';
  const calculateMaxTickets = () => {
    if (!isPromoActive) {
      return quantityAvailable; // No promotion, return available quantity
    }
    // Calculate maximum tickets considering the promotion
    const totalBundles = Math.floor(quantityAvailable / (promo.details.requiredQuantity + promo.details.freeQuantity));
    const purchasableTicketsInBundles = totalBundles * promo.details.requiredQuantity;
    const leftOverTickets = quantityAvailable - (totalBundles * (promo.details.requiredQuantity + promo.details.freeQuantity))
    return (purchasableTicketsInBundles) + ( leftOverTickets);
  };
  
  const maxTickets = calculateMaxTickets();

  useEffect(() => {
    let timer;
    if (holdTimeLeft !== null) {
      if (holdTimeLeft > 0) {
        timer = setTimeout(() => setHoldTimeLeft(holdTimeLeft - 1), 1000);
      } else {
        setHoldTimeLeft(null); // Reset hold time left after countdown ends
      }
    }
    return () => clearTimeout(timer);
  }, [holdTimeLeft]);

  const handleBuyClick = async () => {
    if (!username) {
      setShowLoginReminder(true); // Show login reminder popup
      return;
    }
    setIsBuyOutClick(false);
    setShowTermsPopup(true); // Show Terms and Conditions confirmation popup
  };  

  const confirmPurchase = async () => {
    setShowTermsPopup(false);
    setIsLoading(true);

    // Check if the user has enough tokens
    const response = await fetch(`${backendUrl}/api/token/balance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      const totalTokensRequired = product.token * ticketCount; // Assuming 1 token = $0.10
      
      if (data.totalTokens >= totalTokensRequired) {
        // Show token confirmation popup
        setShowTokenConfirmationPopup(true);
      } else {
        // Show the top-up tokens popup if not enough tokens
        setTokensLacking(totalTokensRequired - data.totalTokens);
        setShowTopUpTokensPopup(true);
      }
    } else {
      if (response.status === 403) {
        setShowLoginReminder(true);
      }
      console.error('Failed to fetch token balance');
      setIsLoading(false);
    }
  };

  const handleTopUp = () => {
    // Redirect to the purchase tokens page
    navigate('/purchase-tokens');
  };

  const handleProceedWithoutTopUp = () => {
    // Proceed with Stripe payment
    initiateStripePayment();
    setShowTopUpTokensPopup(false);
  };

  const initiateStripePayment = async () => {
    if (isBuyOutClick) {
      try {
        const response = await fetch(`${backendUrl}/api/payment/kuji/buy-out`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Add Authorization header
          },
          body: JSON.stringify({ setId: product.setId, username: username }),
          credentials: 'include',
        });
      
        if (response.status === 400) {
          const data = await response.json();
          if (data.message.includes('Not enough tickets left')) {
            setShowRefreshPopup(true); // Show the popup to instruct the user to refresh the page
            setIsLoading(false);
            return;
          }
        }
    
        if (response.status === 403) {
          setShowLoginReminder(true); // Show login reminder popup
          setIsLoading(false);
          return;
        }
    
        if (response.status === 404) {
          const data = await response.json();
          if (data.error.includes('User not found')) {
            setShowLoginReminder(true);
            setIsLoading(false);
            return;
          }
        }
    
        if (response.status === 423) {
          const data = await response.json();
          setHoldTimeLeft(data.holdTimeLeft); // Set the accurate hold time left
          setShowRefreshPopup(true); // Show the popup to instruct the user to refresh the page
          setIsLoading(false);
          return;
        }
    
        if (response.status === 500) {
          const data = await response.json();
          setShowTryAgain(true);
          setIsLoading(false);
          return;
        }
    
        const data = await response.json();
        if (data.clientSecret) {
          setHoldTimeLeft(30); // Start 30-second hold countdown
          dispatch(setPaymentState({ clientSecret: data.clientSecret, ticketsBought: data.ticketsBought, orderId: data.orderId })); // Pass clientSecret, ticketsBought, and orderId to Redux
          navigate('/kuji-payment'); // Navigate to the payment page
        }
        setIsLoading(false); // Stop loading
      } catch (error) {
        console.error('Error:', error);
        setShowTryAgain(true);
        setIsLoading(false);
      }
    } else {
      const response = await fetch(`${backendUrl}/api/payment/kuji/create-intent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ticketsBought: ticketCount, setId: product.setId, username: username }),
        credentials: 'include'
      });
  
      if (response.status === 400) {
        const data = await response.json();
        if (data.message.includes('Not enough tickets left')) {
          setShowRefreshPopup(true);
          setIsLoading(false);
          return;
        }
      }
  
      if (response.status === 404) {
        const data = await response.json();
        if (data.error.includes('User not found')) {
          setShowLoginReminder(true);
          setIsLoading(false);
          return;
        }
      }
  
      if (response.status === 423) {
        const data = await response.json();
        setHoldTimeLeft(data.holdTimeLeft);
        setShowRefreshPopup(true);
        setIsLoading(false);
        return;
      }
  
      if (response.status === 500) {
        const data = await response.json();
        setShowTryAgain(true);
        setIsLoading(false);
        return;
      }
  
      const data = await response.json();
      if (data.clientSecret) {
        dispatch(setPaymentState({ clientSecret: data.clientSecret, ticketsBought: ticketCount, orderId: data.orderId }));
        navigate('/kuji-payment');
      }
      setIsLoading(false);
    }
  };

  const confirmTokenPurchase = async () => {
    setShowTokenConfirmationPopup(false);
    setIsLoading(true);

    try {
        // Call the API to use tokens for the purchase
        const response = await fetch(`${backendUrl}/api/token/use`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                ticketsBought: ticketCount,
                setId: product.setId,
                username: username,
            }),
        });

        if (response.ok) {
            const data = await response.json();

            sessionStorage.setItem('orderState', JSON.stringify({
              orderId: data.orderId,
              ticketsBought: ticketCount,
              selectedProduct: product
            }));

            // After successfully using tokens, call the draw-and-update-order API
            const drawResponse = await fetch(`${backendUrl}/api/draw-and-update-order/${data.orderId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (drawResponse.ok) {
                // If the draw and order update is successful, navigate to the draw page
                navigate(`/draw`);
            } else {
                const errorData = await drawResponse.json();
                alert(`Failed to update order: ${errorData.message}`);
            }
        } else {
            const errorData = await response.json();
            alert(`Purchase failed: ${errorData.message}`);
        }
    } catch (error) {
        console.error('Error during token purchase:', error);
        alert('An error occurred during the purchase process.');
    } finally {
        setIsLoading(false);
    }
  };

  const handleCancelTokenConfirmationPopup = async () => {
    setIsLoading(false);
    setShowTokenConfirmationPopup(false);
  }

  const handleBuyOutClick = async () => {
    if (!username) {
      setShowLoginReminder(true); // Show login reminder popup
      return;
    }
    setIsLoading(true); // Start loading
    setIsBuyOutClick(true);
  
    // Calculate the correct ticket count with promotions
    let totalTicketsToPayFor = quantityAvailable;
  
    if (isPromoActive && promo.type === 'BUY_X_GET_Y_FREE') {
      const requiredQuantity = promo.details.requiredQuantity;
      const freeQuantity = promo.details.freeQuantity;
  
      // Calculate how many full sets of "buy X get Y free" there are
      const totalBundles = Math.floor(quantityAvailable / (requiredQuantity + freeQuantity));
      const paidTicketsInBundles = totalBundles * requiredQuantity;
  
      // Calculate the remaining tickets that don't form a full bundle
      const remainingTickets = quantityAvailable % (requiredQuantity + freeQuantity);
      const additionalPaidTickets = Math.min(remainingTickets, requiredQuantity);
  
      totalTicketsToPayFor = paidTicketsInBundles + additionalPaidTickets;
    }
  
    // Check if the user has enough tokens for the calculated number of tickets
    try {
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      const response = await fetch(`${backendUrl}/api/token/balance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const totalTokensRequired = product.token * totalTicketsToPayFor; // Assuming 1 token = $0.10
  
        if (data.totalTokens >= totalTokensRequired) {
          // If user has enough tokens, show token confirmation popup
          setTicketCount(totalTicketsToPayFor);
          setShowTokenConfirmationPopup(true);
        } else {
          // Show the top-up tokens popup if not enough tokens
          setTokensLacking(totalTokensRequired - data.totalTokens);
          setShowTopUpTokensPopup(true);
          setIsLoading(false); // Stop loading since we are waiting for user input
          return; // Exit the function to wait for user input
        }
      } else {
        if (response.status === 403) {
          setShowLoginReminder(true);
          setIsBuyOutClick(false);
        }
        console.error('Failed to fetch token balance');
        setIsLoading(false);
        setIsBuyOutClick(false);
        return;
      }
    } catch (error) {
      console.error('Error fetching token balance:', error);
      setIsLoading(false);
      setIsBuyOutClick(false);
      return;
    }
  };

  // Function to navigate to the login page
  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  // Function to close the login reminder popup
  const handleCloseLoginReminder = () => {
    setShowLoginReminder(false);
  };

  const increaseTicketCount = () => {
    if (ticketCount < maxTickets) {
      const newCount = Math.min(maxTickets, ticketCount + 1);
      setTicketCount(newCount);
      setInputValue(newCount.toString());
    }
  };

  const decreaseTicketCount = () => {
    if (ticketCount > minimumTicketCount) {
      const newCount = Math.max(minimumTicketCount, ticketCount - 1);
      setTicketCount(newCount);
      setInputValue(newCount.toString());
    }
  };

  const handleTicketInputChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, ''); // Strip non-numeric characters
    setInputValue(rawValue); // Set the raw value initially
  };

  const handleInputBlur = () => {
    let parsedValue = parseInt(inputValue, 10);
    if (isNaN(parsedValue) || parsedValue < minimumTicketCount) {
      parsedValue = minimumTicketCount;
    } else if (parsedValue > maxTickets) {
      parsedValue = maxTickets;
    }
    setTicketCount(parsedValue);
    setInputValue(parsedValue.toString());
  };

  const handleTermsClick = () => {
    navigate('/terms-and-conditions');
  };

  return (
    <div>
        {showTopUpTokensPopup && (
          <TopUpTokensPopup
            onTopUp={handleTopUp}
            onProceed={handleProceedWithoutTopUp}
            tokensLacking={tokensLacking}
          />
        )}
        {showRefreshPopup && <RefreshPopup onClose={() => setShowRefreshPopup(false)} />}
        {showLoginReminder && (
        <LoginReminderPopup onLogin={handleNavigateToLogin} onClose={handleCloseLoginReminder} />
        )}
        {showTryAgain && (
        <TryAgainPopup onClose={()=> setShowTryAgain(false)} />
        )}
        {holdTimeLeft !== null && (
          <HoldTimePopup holdTimeLeft={holdTimeLeft} onClose={() => setHoldTimeLeft(null)} />
        )}
        {showTermsPopup && (
          <div className="terms-popup">
            <div className="terms-content">
              <h2>Terms and Conditions</h2>
              <p>Please agree to the <span onClick={handleTermsClick} style={{color: 'blue', cursor: 'pointer'}}>Terms and Conditions</span> before purchasing.</p>
              <form onSubmit={(e) => {
                e.preventDefault();
                confirmPurchase();
              }}>
                <input type="checkbox" required />
                <label>I agree to the <span onClick={handleTermsClick} style={{color: 'blue', cursor: 'pointer'}}>Terms and Conditions</span></label><br />
                <div className="terms-popup-buttons"></div>
                <button className="terms-popup-confirm-button" type="submit">Next</button>
                <button className="terms-popup-cancel-button" type="button" onClick={() => setShowTermsPopup(false)}>Cancel</button>
              </form>
            </div>
          </div>
        )}
        {showTokenConfirmationPopup && (
          <TokenConfirmationPopup
            totalTokens={product.token * ticketCount}
            onConfirm={confirmTokenPurchase}
            onCancel={handleCancelTokenConfirmationPopup}
          />
        )}
        <div className="play-product-banner" key={product.setId}>
        <div className="play-product-banner-container">
          <div className="play-product-title-box">
            <span className="play-product-title-text">{product.name}</span>
          </div>
          <img src={`${backendUrl}/api/images/${product.image}`} alt={product.name} className="play-product-banner-img" />
        </div>
        {
          product.preorder ? (
            <>
              <div className="play-price-tag-preorder">
                  <span>{product.price}</span>
              </div>
              <div className="play-items-left-tag-preorder">
                  <img src={ticketIcon} alt="Ticket Icon" className="ticket-icon" />
                  <span>{product.quantity}</span>
              </div>
            </>
          ) : (
            <>
              <div className="play-price-tag">
                <span className="token-price">{product.token}<img src={tokenIcon} alt="Token" className="product-token-icon" /> </span>
                <span>${product.price}</span>
              </div>
              <div className="play-items-left-tag">
                  <img src={ticketIcon} alt="Ticket Icon" className="ticket-icon" />
                  <span>{product.quantity}</span>
              </div>
            </>
          )
        }
        <div className="ticket-selection">
            <button className="ticket-decrease-button" onClick={decreaseTicketCount}>-</button>
            <input
              className="ticket-count-input"
              type="text" // Change to text to avoid native number input behavior
              value={inputValue}
              onChange={handleTicketInputChange}
              onBlur={handleInputBlur}
            /> 
          <button className="ticket-increase-button" onClick={increaseTicketCount}>+</button>
        </div>
        <div className="buy-and-arrival">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <button className="play-product-buy-button" disabled={isDisabled} onClick={handleBuyClick}>BUY</button>
            <button className="play-product-buy-out-button" disabled={isDisabled} onClick={handleBuyOutClick}>BUY OUT</button>
          </>
        )}
        {
          product.preorder ? (
            <>
              <span className="play-product-info">PRE-ORDER</span>
              <span className="play-product-arrival">Estimated Arrival: {product.arrival}</span>
            </>
          )
          : (
            <>
              <span className="play-product-info">INSTOCK</span>
            </>
          )
        }
        </div>
        </div>
    </div>
  );
}

export default PlayProductCard;