import React from 'react';
import './PlayInfoPopup.css'; // Update the CSS file accordingly
import closeIcon from './closeicon.png';

function PlayInfoPopup({ onClose, promotion, lottery }) {
  const getPopupContent = () => {
    if (promotion) {
      if (promotion.type === 'BUY_X_GET_Y_FREE') {
        return (
          <>
            <h2 className="play-info-popup-title">PROMOTION</h2>
            <p className="play-info-popup-text">
              BUY <b>{promotion.details.requiredQuantity} TICKETS</b> AND <b> GET {promotion.details.freeQuantity} TICKET</b> FOR <b>FREE</b>!
              <br/><br/>
              Simply check out the required tickets and the free tickets will be given to you while you draw the tickets!
            </p>
          </>
        );
      } else if (promotion.type === 'CHALLENGE') {
        return (
          <>
            <h2 className="play-info-popup-title">CHALLENGE</h2>
            <p className="play-info-popup-text">
              <b>BUY {promotion.details.minimumQuantity} TICKETS</b> AND <b>GET PRIZE {promotion.details.challengeAlphabet}</b> TO GET ALL PRIZES, INCLUDING THE LAST PRIZE!
            </p>
          </>
        );
      }
    }

    if (lottery && lottery.enabled) {
      return (
        <>
          <h2 className="play-info-popup-title">Chance</h2>
          <p className="play-info-popup-text">
            Every ticket you purchase enters you into a draw for a <b>CHANCE to win the Last Prize.</b> The more tickets you purchase, the higher your chances of winning.<br/><br/> 
            <b>After all tickets are sold, a random winner will be selected for the Last Prize.</b> You can check your prizes on the orders page once the draw is complete.
          </p>
        </>
      );
    }

    return null; // In case there's no promotion or lottery
  };

  return (
    <div className="play-info-popup-overlay">
      <div className="play-info-popup-content">
        {getPopupContent()} {/* Dynamically show the content */}
        <button className="play-info-popup-close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default PlayInfoPopup;