// src/redux/slices/productSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    selectedProduct: null,
  },
  reducers: {
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    clearSelectedProduct: (state) => {
      state.selectedProduct = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedProduct, clearSelectedProduct } = productSlice.actions;

export default productSlice.reducer;