import React, { useState, useRef, useEffect } from 'react';
import { useGesture } from 'react-use-gesture';
import './TicketAnimationPopup.css'; // Ensure your CSS file path is correct
import ticketEffect from './ticketeffect.png'; // Import the ticket effect image
import ticketBackGold from './ticket-back-gold.png';
import ticketBackSilver from './ticket-back-silver.png';
import ticketBackBronze from './ticket-back-bronze.png';

import spriteSheet from './ticket-animation.png'; // Ensure your image path is correct
import spriteData from './ticket-animation.json'; // Ensure your JSON file path is correct

const TicketAnimationPopup = ({ isOpen, onClose, selectedPrize, ticketsLeftToDraw, challengeAlphabet }) => {
  const [frame, setFrame] = useState(0);
  const [showTicketBack, setShowTicketBack] = useState(false);
  const [sliderValue, setSliderValue] = useState(0); // Slider state
  const [showChallengePopup, setShowChallengePopup] = useState(false); // New state for challenge popup

  const frames = spriteData.frames;
  const frameCount = frames.length;
  const animationRef = useRef(null);

  const lastFrame = useRef(0); // Store the last frame
  
  const bind = useGesture({
    onDragStart: ({ initial }) => {
      // Initialize lastFrame based on current slider value to maintain continuity
      lastFrame.current = Math.floor((sliderValue / 100) * frameCount);
    },
    onDrag: ({ movement: [mx], down, direction: [xDir] }) => {
      if (down && xDir >= 0) { // Only allow dragging right
        const distanceFromStart = mx; // This is the drag distance from the start point
        const potentialFrame = lastFrame.current + Math.floor((distanceFromStart / window.innerWidth) * frameCount);
        const newFrame = Math.min(Math.max(lastFrame.current, potentialFrame), frameCount - 1); // Ensure it does not go backwards
        if (newFrame !== frame) {
          setFrame(newFrame);
          setSliderValue((newFrame / frameCount) * 100);
        }
      }
    },
    onDragEnd: () => {
      // Update lastFrame to the latest to prevent backtracking on subsequent drags
      lastFrame.current = Math.floor((sliderValue / 100) * frameCount);
    }
  });


  const autoOpenTicket = () => {
    clearInterval(animationRef.current);
    animationRef.current = setInterval(() => {
      setFrame(currentFrame => {
        const nextFrame = currentFrame + 1;
        setSliderValue((nextFrame / frameCount) * 100); // Update slider value
        if (nextFrame < frameCount - 1) {
          return nextFrame;
        } else {
          clearInterval(animationRef.current);
          return currentFrame;
        }
      });
    }, 16); // Adjust timing for smooth animation
  };

  const { x, y, w, h } = frames[frame].frame;
  const bgPosition = `-${x}px -${y}px`;

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    if (isOpen) {
      setFrame(0); // Reset the frame when the popup opens
      setSliderValue(0); // Reset the slider when the popup opens
      clearInterval(animationRef.current); // Clear the interval
      const timer = setTimeout(() => {
        setShowTicketBack(true);
      }, 150); // Adjust delay as necessary
      return () => clearTimeout(timer);
    } else {
      clearInterval(animationRef.current); // Ensure cleanup on component unmount
      setShowTicketBack(false); // Reset when popup is closed
    }
  }, [isOpen]);

  const handleNextTicket = () => {
    // Logic to draw the next ticket or close the current popup
    // You can call onClose or another function here
    onClose(); // Example: Close the popup, you might want to replace or complement this with your actual logic
    setShowTicketBack(false); // Reset when popup is closed
    clearInterval(animationRef.current);
  };

  useEffect(() => {
    if (frame > frameCount - 30 && selectedPrize === challengeAlphabet) {
      setShowChallengePopup(true);
    }
  }, [frame, challengeAlphabet, selectedPrize, frameCount]);

  const prize = selectedPrize;

  let ticketBackImage;
  if (['A', 'B', 'C'].includes(prize)) {
    ticketBackImage = ticketBackGold;
  } else if (['D', 'E', 'F'].includes(prize)) {
    ticketBackImage = ticketBackSilver;
  } else {
    ticketBackImage = ticketBackBronze;
  }

  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
        <div className="popup-content" {...bind()} onClick={(e) => e.stopPropagation()}>
            {frame > frameCount - 30 && (
                <img src={ticketEffect} alt="Ticket Effect" className="ticket-effect" />
            )}
            <div className="ticket-animation-wrapper">
                {frame > frameCount - 30 && (
                    <div className="congratulations-text">Congratulations!</div>
                )}
                {/* Background Image */}
                {showTicketBack && (
                    <img src={ticketBackImage} alt="Ticket Background" className="ticket-back" />
                )}
                {frame > 10 &&
                    <div className="ticket-prize-text">{prize}赏</div>
                }

                {/* Animation Overlay */}
                <div
                    className="animation-container"
                    style={{
                        backgroundImage: `url(${spriteSheet})`,
                        backgroundPosition: bgPosition,
                        width: `${w}px`,
                        height: `${h}px`,
                    }}
                />
                <input type="range" min="0" max="100" value={sliderValue} className="ticket-slider" readOnly />
            </div>
            
            {frame > frameCount - 30 ? (
                ticketsLeftToDraw > 0
                ? <button className="ticket-animation-next-button" onClick={handleNextTicket}>Draw Next Ticket</button>
                : <button className="ticket-animation-next-button" onClick={handleNextTicket}>Show Results</button>
            ) : (
                <>
                  <div className="slide-prompt">Slide from left to right or press the button below to tear open the ticket</div>
                  <button className="open-ticket-button" onClick={autoOpenTicket}>Open Ticket</button>
                </>
            )}

            {showChallengePopup && (
              <div className="challenge-popup">
                <div className="challenge-popup-content">
                  <h2>Challenge Won!</h2>
                  <p>Congratulations! You've won the challenge and will receive the remaining prizes including the Last Prize!</p>
                  <button onClick={() => setShowChallengePopup(false)}>Close</button>
                </div>
              </div>
            )}
        </div>
    </div>
  );
};

export default TicketAnimationPopup;