import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavBar from './NavBar';
import './PurchaseTokensPage.css';
import tokenIcon from './surprize_token.png';
import promoBanner from './promo_banner.png'; // Import your promo banner
import { setPaymentState } from './redux/slices/paymentSlice'; // Import your Redux action for setting payment state
import { useNavigate } from 'react-router-dom';

function PurchaseTokensPage() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [tokenPackages, setTokenPackages] = useState([]);
  const [selectedPackageId, setSelectedPackageId] = useState(null); // State for selected package
  const [showTermsPopup, setShowTermsPopup] = useState(false); // State to control showing the terms popup
  const token = localStorage.getItem('token');
  const username = useSelector(state => state.user.username);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTokenPackages();
  }, []);

  const fetchTokenPackages = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/token/packages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setTokenPackages(data.tokenPackages || []);
      } else {
        console.error('Failed to fetch token packages');
      }
    } catch (error) {
      console.error('Error fetching token packages:', error);
    }
  };

  const handleTermsClick = () => {
    navigate('/terms-and-conditions'); // Navigate to the terms page if needed
  };

  const handlePurchaseClick = (packageId) => {
    setSelectedPackageId(packageId); // Set the selected package ID
    setShowTermsPopup(true); // Show the terms popup
  };

  const confirmPurchase = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/payment/token/create-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ packageId: selectedPackageId, username }),
      });

      if (response.ok) {
        const data = await response.json();
        dispatch(setPaymentState({ clientSecret: data.clientSecret, paymentIntentId: data.paymentIntentId, tokenPackage: data.tokenPackage }));
        navigate('/token-payment');
      } else {
        console.error('Failed to initiate token purchase');
        alert('Failed to initiate token purchase. Please try again.');
      }
    } catch (error) {
      console.error('Error initiating token purchase:', error);
      alert('An error occurred while initiating token purchase.');
    }
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div className="purchase-tokens-container">
          <div className="purchase-tokens-promo-banner-container">
            <img src={promoBanner} alt="Promo Banner" className="purchase-tokens-promo-banner" />
          </div>
          <h1 className="purchase-tokens-title">Token Packages</h1>
          <div className="token-packages">
            {tokenPackages.length === 0 ? (
              <p>No token packages available.</p>
            ) : (
              tokenPackages.map((pkg) => {
                // Calculate discount percentage if originalPrice exists
                const hasDiscount = pkg.originalPrice && pkg.originalPrice > pkg.price;
                const discountPercentage = hasDiscount ? ((pkg.originalPrice - pkg.price) / pkg.originalPrice) * 100 : 0;

                return (
                  <div key={pkg._id} className="token-package">
                    <h2>{pkg.packageName} Package</h2>
                    <div className='token-package-description'>
                      <p className="amount-description">
                        <img src={tokenIcon} alt="Token" className="token-icon" /> {pkg.tokenAmount}
                      </p>
                      <p>
                        <strong>Price: </strong> 
                        <span style={{ color: 'green' }}>${ (pkg.price / 100).toFixed(2) }</span>
                        {hasDiscount && (
                          <>
                            &nbsp;<span className="discount-percentage" style={{ color: 'green' }}>({discountPercentage.toFixed(0)}% OFF)</span>
                            &nbsp;<span className="original-price" style={{ textDecoration: 'line-through', color: 'black' }}>${ (pkg.originalPrice / 100).toFixed(2) }</span>
                          </>
                        )}
                      </p>
                    </div>
                    <button 
                      className="purchase-button" 
                      onClick={() => handlePurchaseClick(pkg._id)} // Show the terms popup before proceeding
                    >
                      Purchase
                    </button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      {/* Terms and Conditions Popup */}
      {showTermsPopup && (
        <div className="token-terms-popup">
          <div className="token-terms-content">
            <h2>Terms and Conditions</h2>
            <p>Please agree to the <span onClick={handleTermsClick} style={{ color: 'blue', cursor: 'pointer' }}>Terms and Conditions</span> before purchasing.</p>
            <form onSubmit={(e) => {
              e.preventDefault();
              confirmPurchase(); // Call confirm purchase when terms are accepted
            }}>
              <input type="checkbox" required />
              <label>I agree to the&nbsp;<span onClick={handleTermsClick} style={{ color: 'blue', cursor: 'pointer' }}>Terms and Conditions</span></label><br />
              <div className="token-terms-popup-buttons">
                <button className="token-terms-popup-confirm-button" type="submit">Next</button>
                <button className="token-terms-popup-cancel-button" type="button" onClick={() => setShowTermsPopup(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default PurchaseTokensPage;