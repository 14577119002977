import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import logo from './logo.png';
import LoadingSpinner from './LoadingSpinner';

function ResetPasswordPage() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const handleResetPasswordSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(`${backendUrl}/api/users/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
                credentials: 'include'
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.message || 'Failed to reset password');
                setIsLoading(false);
                return;
            }

            alert('Password has been reset successfully.');
            navigate('/login');
        } catch (error) {
            setIsLoading(false);
            console.error('Reset password error:', error);
            setError('An error occurred while resetting your password.');
        }
    };

    return (
        <div>
            <NavBar />
            <div className="gradient-background">
                <div style={styles.logoContainer}>
                    <img src={logo} alt="Logo" style={styles.logo} />
                </div>
                <div style={styles.container}>
                    <form onSubmit={handleResetPasswordSubmit} style={styles.form}>
                        <h2 style={styles.h2Text}>Reset Password</h2>
                        <div style={styles.formContent}>
                            <input
                                type="password"
                                id="password"
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={styles.input}
                            />
                            <input
                                type="password"
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                style={styles.input}
                            />
                            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
                            {
                              isLoading
                              ? (
                                <LoadingSpinner />
                              )
                              : (
                                <button type="submit" style={styles.button}>
                                  Reset Password
                                </button>
                              )
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

// Reuse the styles from the LoginPage with necessary adjustments
const styles = {
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '50px'
    },
    logo: {
      height: '100px', // Adjust the size as needed
    },
    formContent: {
      alignItems: 'center',
      width: '100%',
    },
    input: {
      width: '70%', // 100% minus padding
      padding: '15px',
      marginTop: '5px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      textAlign: 'center', // Attempt to center align the text
      // Additional styling for the placeholder
      '::placeholder': {
          textAlign: 'center', // This may not be supported in all browsers
      },
      fontSize: '15px'
    },
    button: {
      width: '60%', // Adjust the width as needed
      padding: '15px',
      marginTop: '40px',
      backgroundColor: '#4CAF50', // Base color for the button
      color: 'white',
      border: '1px solid green',
      borderRadius: '4px',
      cursor: 'pointer',
      boxShadow: '0 6px #2e7d32', // Shadow effect for 3D look
      fontSize: '15px'
    },
    buttonDisabled: {
      backgroundColor: '#A9A9A9', // Dark gray background
      color: '#D3D3D3', // Light gray text
      cursor: 'not-allowed', // Cursor to indicate the button is disabled
      boxShadow: 'none', // Remove shadow for a flat appearance
      border: '2px solid #D3D3D3', // Adding a border, adjust color as needed
    },
    infoText: {
      textAlign: 'center',
      color: '#666', // You can change the color as needed
      fontSize: '15px'
    },
    h2Text: {
      textAlign: 'center', // Center align text for headings
    },
    h4Text: {
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '10px'
    },
    newUserButton: {
      backgroundColor: 'transparent',
      color: 'gray',
      textDecoration: 'underline',
      border: 'none',
      cursor: 'pointer',
      marginTop: '20px',
      fontSize: '16px',
    },
    forgetPasswordButton: {
      backgroundColor: 'transparent',
      color: '#A95C68', // White text color for contrast
      textDecoration: 'underline',
      border: 'none',
      cursor: 'pointer',
      marginTop: '10px', // Spacing from the button above
      fontSize: '16px',
      padding: '10px 15px', // Padding for button size
      borderRadius: '4px', // Rounded corners
    },
};

export default ResetPasswordPage;
