// src/redux/slices/paymentSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    clientSecret: null,
    ticketsBought: 0, // Initial state for ticketsBought
    paymentSuccess: false,
    orderId: null,
    shipmentId: null,
    shipmentOrders: null,
    tokenPackage: null,
    paymentIntentId: null,
    promoCode: null
  },
  reducers: {
    setPaymentState: (state, action) => {
      state.clientSecret = action.payload.clientSecret; // Assuming action.payload is an object with clientSecret and ticketsBought
      state.ticketsBought = action.payload.ticketsBought; // Set ticketsBought from action
      state.orderId = action.payload.orderId;
      state.shipmentId = action.payload.shipmentId;
      state.shipmentOrders = action.payload.shipmentOrders;
      state.tokenPackage = action.payload.tokenPackage;
      state.paymentIntentId = action.payload.paymentIntentId;
      state.promoCode = action.payload.promoCode;
    },
    setPaymentSuccess: (state, action) => {
      state.paymentSuccess = action.payload;
    },
    resetPayment: (state) => {
      state.clientSecret = null;
      state.ticketsBought = 0; // Reset ticketsBought
      state.paymentSuccess = false;
      state.orderId = null;
      state.shipmentId = null;
      state.shipmentOrders = null;
      state.tokenPackage = null;
      state.paymentIntentId = null;
      state.promoCode = null;
    },
  },
});

export const { setPaymentState, setPaymentSuccess, resetPayment } = paymentSlice.actions;

export default paymentSlice.reducer;