import React, { useEffect } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import logo from './logo.png'; // Your logo image
import inventoryImage from './inventory.jpg'; // An image showing your inventory
import tiktokImage from './tiktok-shop.png'; // An image of your TikTok shop
import './AboutUs.css'; // Import the stylesheet you created

function AboutUs() {

    useEffect(() => {
        window.scroll(0, 0);
    })

    return (
        <div>
            <NavBar />
            <div className="about-us-container">
                <img src={logo} alt="Logo" className="about-us-logo" />
                <h1 className="about-us-heading">About Us</h1>
                <p className="about-us-text">
                    We are proud to be the top authentic Ichiban Kuji shop in Singapore on TikTok, known for our extensive product range. Based in Singapore, we cater to all fans of anime and unique collectibles, providing an unmatched Ichiban Kuji experience.
                </p>
                <img src={tiktokImage} alt="TikTok Shop" className="about-us-image" />
                <p className="about-us-text">
                    Our inventory includes a wide range of authentic prizes and exclusive items, from Ichiban Kuji to Anime Figures and Blindboxes.
                </p>
                <img src={inventoryImage} alt="Our Inventory" className="about-us-image" />
                <p className="about-us-text">
                    Follow us on TikTok to catch live unboxings, participate in giveaways, and stay updated on the latest arrivals and special events!
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;