// src/redux/rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import paymentReducer from './slices/paymentSlice';
import productReducer from './slices/productSlice';
import prizesReducer from './slices/prizesSlice';
import authReducer from './slices/authSlice';
// Import other slices if you have more

const rootReducer = combineReducers({
  user: userReducer,
  payment: paymentReducer,
  product: productReducer,
  prizes: prizesReducer,
  auth: authReducer // add the auth slice
  // other state slices
});

export default rootReducer;