import React from 'react';
import './ShipmentSuccessPopup.css'; // Style your popup accordingly

const ShipmentSuccessPopup = ({ onClose }) => {
    return (
      <div className="shipment-success-popup-overlay">
        <div className="shipment-success-popup-content">
          <h2>Order Successfully Shipped!</h2>
          <p>You will receive updates through SMS to your phone number.</p>
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    );
};

export default ShipmentSuccessPopup;