import React, { useState, useEffect } from 'react';
import ticketIcon from './ticket.png';
import duckIcon from './duck.png';
import tokenIcon from './surprize_token.png';
import starIcon from './star.png';
import './ProductCard.css';
import LoadingSpinner from './LoadingSpinner';

function ProductCard({ product, onPlayClick, onImageLoad }) {

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [isImageLoading, setIsImageLoading] = useState(true); // State to track image loading

  let lotteryEnabled = false;

  if (product.lottery) {
    if (product.lottery.enabled) {
      lotteryEnabled = true;
    }
  }

  return (
    <div>
        <div className="product-banner" key={product.setId}>

        <div className="product-title-box">
          <span className="product-title-text">{product.name}</span>
        </div>

        {isImageLoading && <LoadingSpinner />}

        <div className="product-image-wrapper">
          <img
            src={`${backendUrl}/api/images/${product.image}`}
            alt={product.name}
            className={`product-poster ${isImageLoading ? 'hidden' : ''}`}
            onLoad={() => setIsImageLoading(false)} // Set loading to false when image has loaded
          />
        </div>
        {/* {
          product.promotion
          ? (            
            <div className="product-text-box-promotion">
              {
                product.promotion.type === 'BUY_X_GET_Y_FREE'
                ? <p className="product-card-text-top promotion">PROMOTION: Buy {product.promotion.details.requiredQuantity} Get {product.promotion.details.freeQuantity} FREE</p>
                : null
              }
              {
                product.promotion.type === 'CHALLENGE'
                ? <p className="product-card-text-top challenge">CHALLENGE: Buy {product.promotion.details.minimumQuantity} tickets, Get {product.promotion.details.challengeAlphabet} to get ALL prizes (inc. Last Prize)!</p>
                : null
              }
              {
                product.preorder
                ? <p className="product-card-text status">PRE-ORDER</p>
                : <p className="product-card-text status">INSTOCK</p>
              }
              {
                product.doubleChance 
                ? <p className="product-card-text doublechance">Double Chance: Available</p>
                : <p className="product-card-text doublechance">Double Chance: Not Available</p>
              }
            </div>
          )
          : lotteryEnabled
          ? (            
            <div className="product-text-box-lottery">
              <p className="product-card-text-top lottery">CHANCE: Every ticket gets a chance to win the Last Prize.</p>
              {
                product.preorder
                ? <p className="product-card-text status">PRE-ORDER</p>
                : <p className="product-card-text status">INSTOCK</p>
              }
              {
                product.doubleChance 
                ? <p className="product-card-text doublechance">Double Chance: Available</p>
                : <p className="product-card-text doublechance">Double Chance: Not Available</p>
              }
            </div>
          )
          : (
            <div className="product-text-box">
            {
              product.preorder
              ? <p className="product-card-text-top status">PRE-ORDER</p>
              : <p className="product-card-text-top status">INSTOCK</p>
            }
            {
              product.doubleChance 
              ? <p className="product-card-text doublechance">Double Chance: Available</p>
              : <p className="product-card-text doublechance">Double Chance: Not Available</p>
            }
            </div>
          )
        } */}
        <div className="price-tag">
            <span className="token-price">{product.token}<img src={tokenIcon} alt="Token" className="product-token-icon" /> </span>
            <span>${product.price}</span>
        </div>
        <div className={`items-left-tag ${lotteryEnabled ? 'lottery-enabled' : ''} ${product.promotion ? 'promotion-enabled' : ''}`}>
          <img 
            src={
              lotteryEnabled 
              ? duckIcon 
              : product.promotion 
              ? starIcon
              : ticketIcon
            } 
            alt="Icon" 
            className="ticket-icon" 
          />
          <div className="product-card-items-left-tag-chance">
            { lotteryEnabled ? <span className="product-card-chance-label">CHANCE!</span> : null }
            {product.promotion && product.promotion.type === 'BUY_X_GET_Y_FREE' && (
              <span className="product-card-promotion-label">
                Buy {product.promotion.details.requiredQuantity} Get {product.promotion.details.freeQuantity} FREE!
              </span>
            )}  
            {product.promotion && product.promotion.type === 'CHALLENGE' && (
              <span className="product-card-promotion-label">
                CHALLENGE (Get {product.promotion.details.challengeAlphabet})!
              </span>
            )}            
            <span>{product.quantity}</span>
          </div>
        </div>
        {/* Check if a promotion exists and it's a BUY_X_GET_Y_FREE type
        <div className="promotion-container">
        {product.promotion && product.promotion.type === 'BUY_X_GET_Y_FREE' && (
          <div className="promotion-tag">
            PROMOTION!<br/>
            Buy {product.promotion.details.requiredQuantity} Get {product.promotion.details.freeQuantity} FREE
          </div>
        )}
        </div> */}
        <button className="product-play-button" onClick={() => onPlayClick(product)}>Play</button>
        </div>
    </div>
  );
}

export default ProductCard;