// src/components/FAQ.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import './FAQ.css';

function FAQ() {
    const [openFAQ, setOpenFAQ] = useState(null);

    const toggleFAQ = index => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    // Separate FAQs into different sections
    const generalFAQs = [
        {
            question: "Ichiban Kuji Rules",
            answer: (
                <>
                Ichiban Kuji is a game of chance lottery. Prizes are picked randomly.<br/><br/>
                Every ticket purchased gets you a prize - be it big or small!
                </>
            )
        },
        {
            question: "How are the odds for the Kuji sets updated?",
            answer: (
                <>
                The odds of winning each prize are updated in real-time and are only affected by server speeds and the number of participants.<br/><br/>
                Please refresh the page for updated odds and quantity.
                </>
            )
        },
        {
            question: "Can we choose the designs of the prizes we won?",
            answer: "Currently, the designs for the prizes will be picked at random. However, we plan to allow you to choose the designs in the near future!"
        },
        {
            question: "How do Pre-Order sets work?",
            answer: "Pre-Order sets are Ichiban Kuji sets released in the next few months. The estimated arrival is stated in the product description page, subjected to changes. You will receive an email or SMS update when the product arrives and is ready to be shipped to you. You will have to arrange shipment and pay the shipping cost within two weeks."
        },
        {
            question: "Do the sets have Double Chance?",
            answer: "Availability of Double Chance varies from set to set. It will be stated in the product description page. Orders for sets with Double Chance will be shipped together with the ticket stubs."
        },
        {
            question: "Can I refund or return my orders?",
            answer: (
                <>After successful payment, orders are non-refundable and non-returnable.
                These products are mass-produced and cannot be guaranteed to be free of defects, even if brand new.<br/><br/>
                Please order at your own risk as packaging may be damaged during shipping.</>
            )
        },
        {
            question: "Do you do wholesale for Kuji sets?",
            answer: <>Yes, we do. For more information, please contact us <Link to="/contact-us" style={{ color: '#1a0dab' }}>here</Link>.</>
        },
    ];

    const shippingFAQs = [
        {
            question: "Do you ship outside of Singapore?",
            answer: "No. Currently we are only operating in and deliver to Singapore customers."
        },
        {
            question: "Do the prices include Shipping Costs?",
            answer: (
            <>No. Prices listed on our website do not include shipping costs.
            Shipping fees are calculated and added based on your destination and the prizes you win after you open your kuji tickets. <br/><br/>
            For more information, please check out the <Link to="/shipping-and-delivery" style={{ color: '#1a0dab' }}>Shipping and Delivery</Link> page.</>
            )
        },
        {
            question: "How long does delivery take?",
            answer: (
            <>After you arrange shipment for your orders, it will typically be delivered in 2 to 4 working days. <br/><br/>
            For more information, please check out the <Link to="/shipping-and-delivery" style={{ color: '#1a0dab' }}>Shipping and Delivery</Link> page.</>
            )
        },
        {
            question: "How long do I have before I have to arrange shipment and pay the shipping cost for my orders?",
            answer: "Orders can be retained with us for a maximum of two weeks. If the shipping is not arranged within the time period, the prizes for the orders will be considered forfeited."
        }
    ];

    const tokenFAQs = [
        {
            question: "What are tokens for?",
            answer: <>Tokens are alternative currencies that you pre-store in Surprize.
            You can use tokens to purchase tickets from various kuji sets!</>
        },
        {
            question: "Do the tokens expire?",
            answer: <>Yes. Tokens have an expiry date of 6 months from the date of purchase!</>
        },
        {
            question: "Why are there different token packages?",
            answer: <>The different token packages have different value to cater to most of you. <br/><br/>
            The bigger token package your purchase, the more tokens you get, so get your money's worth!</>
        },
        {
            question: "Can I refund the tokens I purchased?",
            answer: <>No. Tokens purchased are non-refundable.</>
        },
    ];

    // Function to render FAQs for a section
    const renderFAQSection = (faqs, sectionTitle) => (
        <div className='faq-section'>
            <h2 className='faq-section-title'>{sectionTitle}</h2>
            {faqs.map((faq, index) => (
                <div key={index}>
                    <button className='faq-question' onClick={() => toggleFAQ(faq.question)}>
                        {faq.question}
                    </button>
                    {openFAQ === faq.question && (
                        <div className='faq-answer'>{faq.answer}</div>
                    )}
                </div>
            ))}
        </div>
    );

    return (
        <div>
            <NavBar />
            <div className='faq-container'>
                <h1 className='faq-title'>Frequently Asked Questions</h1>

                {/* Render different sections of FAQs */}
                {renderFAQSection(generalFAQs, "General")}
                {renderFAQSection(shippingFAQs, "Shipping Related")}
                {renderFAQSection(tokenFAQs, "Token Related")}

            </div>
            <Footer />
        </div>
    );
}

export default FAQ;