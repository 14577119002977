import React from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ShippingPaymentForm from './ShippingPaymentForm'; // Assume you create this form similar to KujiPaymentForm

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function ShippingPaymentPage() {
  const clientSecret = useSelector((state) => state.payment.clientSecret); // Ensure you have a way to set this in your Redux store after creating the intent

  const appearance = {
    theme: 'stripe',
    // Add more customization options here
  };

  const options = { clientSecret, appearance };

  return (
    <Elements stripe={stripePromise} options={options}>
      <ShippingPaymentForm />
    </Elements>
  );
}

export default ShippingPaymentPage;