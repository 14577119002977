import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser  } from './redux/slices/userSlice';
import NavBar from './NavBar'; // Import NavBar component
import logo from './logo.png'; // Import your logo
import LoginPromptPopup from './LoginPromptPopup';
import LoadingSpinner from './LoadingSpinner';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation();

  const dispatch = useDispatch();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  },[])

  useEffect(() => {
    // Check if the navigation state includes a prompt for login
    if (location.state?.from === 'auth') {
      setShowLoginPrompt(true);
    }
  }, [location]);

  const handleNewUserClick = () => {
    navigate('/verify-email'); // Redirect to VerifyEmailPage
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/api/users/login`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
          credentials: 'include' // Ensure this matches your backend configuration; it might not be needed if you're using JWT for auth
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token); // Store the JWT token in localStorage
        dispatch(setUser({username: data.user.username, profilePicture: data.user.profilePicture}));
        setIsLoading(false);
        navigate('/'); // Redirect to the homepage or another page as needed
      } else {
        setErrorMessage(data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Login error:', error);
      setIsLoading(false);
    }
  };

  const handleClosePromptPopup = () => {
    setShowLoginPrompt(false);
  }

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        {showLoginPrompt && (
          <LoginPromptPopup onClose={handleClosePromptPopup} />
        )}
        <div style={styles.logoContainer}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.container}>
          {/* Login Form */}
          <form onSubmit={handleLoginSubmit} style={styles.form}>
            <h2 style={styles.h2Text}>Sign In</h2>
            <div style={styles.formContent}>
              <input
                type="text"
                id="username"
                placeholder="Username / Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={styles.input}
              />
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
              />
              {errorMessage && <div style={styles.errorText}>{errorMessage}</div>}
              {isLoading
              ? <LoadingSpinner />
              : <button type="submit" style={styles.button}>Login</button>
              }
            </div>
          </form>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <button 
              onClick={handleNewUserClick} 
              style={styles.newUserButton}
            >
              New here?
            </button>
            <button
              onClick={() => navigate('/reset-password-verify')}
              style={ styles.forgetPasswordButton } 
            >
              Forgot Password?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px'
  },
  logo: {
    height: '100px', // Adjust the size as needed
  },
  formContent: {
    alignItems: 'center',
    width: '100%',
  },
  input: {
    width: '70%', // 100% minus padding
    padding: '15px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    textAlign: 'center', // Attempt to center align the text
    // Additional styling for the placeholder
    '::placeholder': {
        textAlign: 'center', // This may not be supported in all browsers
    },
    fontSize: '15px'
  },
  button: {
    width: '60%', // Adjust the width as needed
    padding: '15px',
    marginTop: '40px',
    backgroundColor: '#4CAF50', // Base color for the button
    color: 'white',
    border: '1px solid green',
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: '0 6px #2e7d32', // Shadow effect for 3D look
    fontSize: '15px'
  },
  buttonDisabled: {
    backgroundColor: '#A9A9A9', // Dark gray background
    color: '#D3D3D3', // Light gray text
    cursor: 'not-allowed', // Cursor to indicate the button is disabled
    boxShadow: 'none', // Remove shadow for a flat appearance
    border: '2px solid #D3D3D3', // Adding a border, adjust color as needed
  },
  infoText: {
    textAlign: 'center',
    color: '#666', // You can change the color as needed
    fontSize: '15px'
  },
  h2Text: {
    textAlign: 'center', // Center align text for headings
  },
  h4Text: {
    textAlign: 'center',
    marginTop: '50px',
    marginBottom: '10px'
  },
  newUserButton: {
    backgroundColor: 'transparent',
    color: 'gray',
    textDecoration: 'underline',
    border: 'none',
    cursor: 'pointer',
    marginTop: '20px',
    fontSize: '16px',
  },
  forgetPasswordButton: {
    backgroundColor: 'transparent',
    color: '#A95C68', // White text color for contrast
    textDecoration: 'underline',
    border: 'none',
    cursor: 'pointer',
    marginTop: '10px', // Spacing from the button above
    fontSize: '16px',
    padding: '10px 15px', // Padding for button size
    borderRadius: '4px', // Rounded corners
  },
  errorText: {
    color: 'red', // Red color for errors
    marginTop: '10px', // Space above the error message
    textAlign: 'center' // Center the text
  },
};

export default LoginPage;