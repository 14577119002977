import React, { useState } from 'react';
import NavBar from './NavBar';
import logo from './logo.png'; // Import your logo
import { useNavigate, useLocation } from 'react-router-dom';

function VerifyResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleEmailSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!isValidEmail(email)) {
      setIsLoading(false);
      console.log('Invalid email');
      return;
    }

    // Check if the email exists and send the reset password link
    try {
      const response = await fetch(`${backendUrl}/api/users/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
        credentials: 'include'
      });

      if (response.ok) {
        setIsLoading(false);
        setEmailSent(true);
      } else {
        const data = await response.json();
        setIsLoading(false);
        setEmailError(data.message || "Failed to send reset link.");
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      setEmailError('An error occurred while processing your request.');
    }
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await fetch(`${backendUrl}/api/users/check-email-reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
        credentials: 'include'
      });

      const data = await response.json();
      return response.ok;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const isValidEmail = email => {
    // Simple email validation pattern
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  return (
    <div>
      <NavBar />
      <div className="gradient-background">
        <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <div style={styles.container}>
            {!emailSent ? (
              <form onSubmit={handleEmailSubmit} style={styles.form}>
                <h2 style={styles.h2Text}>Reset Password</h2>
                <div style={styles.formContent}>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                  />
                  {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                  <p style={styles.infoText}>You will receive a link to reset your password in your email.</p>
                  <button 
                    type="submit" 
                    style={styles.button}
                    disabled={!isValidEmail(email)}
                  >
                    Continue
                  </button>
                </div>
              </form>
            ) : (
              <div style={styles.messageContainer}>
                <p style={styles.infoText}>Please check your email and click on the link to reset your password.</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

// Use the same styles from LoginPage or adjust as needed
const styles = {
  messageContainer: {
    // Style for the message container
    textAlign: 'center',
    marginTop: '20px',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '50px'
    },
    logo: {
      height: '100px', // Adjust the size as needed
    },
    input: {
      width: '80%', // 100% minus padding
      padding: '15px',
      marginTop: '5px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      textAlign: 'center', // Attempt to center align the text
      // Additional styling for the placeholder
      '::placeholder': {
          textAlign: 'center', // This may not be supported in all browsers
      },
      fontSize: '15px'
    },
    button: {
      width: '60%', // Adjust the width as needed
      padding: '15px',
      marginTop: '30px',
      backgroundColor: '#4CAF50', // Base color for the button
      color: 'white',
      border: '1px solid green',
      borderRadius: '4px',
      cursor: 'pointer',
      boxShadow: '0 6px #2e7d32', // Shadow effect for 3D look
      fontSize: '15px'
    },
    buttonDisabled: {
      backgroundColor: '#A9A9A9', // Dark gray background
      color: '#D3D3D3', // Light gray text
      cursor: 'not-allowed', // Cursor to indicate the button is disabled
      boxShadow: 'none', // Remove shadow for a flat appearance
      border: '2px solid #D3D3D3', // Adding a border, adjust color as needed
    },
    infoText: {
      textAlign: 'center',
      color: '#666', // You can change the color as needed
      fontSize: '15px',
      width: '80%'
    },
    h2Text: {
      textAlign: 'center', // Center align text for headings
    },
    h4Text: {
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '10px'
    },
};

export default VerifyResetPasswordPage;