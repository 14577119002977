// src/redux/slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { username: null, profilePicture: null }, // Assuming you want to store profilePicture as well
  reducers: {
    setUser: (state, action) => {
      state.username = action.payload.username; // Assuming payload would be an object { username: ..., profilePicture: ... }
      state.profilePicture = action.payload.profilePicture;
    },
    logoutUser: (state) => {
      state.username = null;
      state.profilePicture = null;
    },
  },
});

export const { setUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;